import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import * as React from 'react';

import useStyles from './Whatsapp.styles';

const Whatsapp = () => {
  const { icon } = useStaticQuery(graphql`
    {
      icon: file(relativePath: { eq: "whatsapp_icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `);

  const imageSrc = getSrc(icon.childImageSharp.gatsbyImageData);

  const classes = useStyles(imageSrc)();

  return (
    <a
      className={classes.root}
      href="https://api.whatsapp.com/send/?phone=27769900359&text=Please+add+me+to+Lifehouse+Church+Broadcast+List&app_absent=0"
      target="_blank"
    ></a>
  );
};

export default Whatsapp;
