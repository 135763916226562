import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  const color = theme.palette.augmentColor({ main: '#2b323a' });

  return createStyles({
    video: {
      borderRadius: 4,
    },
    vision: {
        listStyle: 'none',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginTop: 16,
        padding: 0,
        "& li": {
          ...theme.typography.h3,
          letterSpacing: 4,
          position: "relative",
          color: '#2d3436',
        },
        "& li:not(:last-child):after": {
          content: '" "',
          width: 8,
          height: 8,
          backgroundColor: '#2d3436',
          borderRadius: 10,
          position: "absolute",
          top: 0,
          bottom: 0,
          right: -28,
          margin: "auto",
        },
        "& li:not(:last-child)": {
          marginRight: 48,
        },
        
    },
    paragraph: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
        '& span': {
            fontWeight: 500,
        }
    }
  })
});

export default useStyles;