import { Container, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import useStyles from "./History.styles";

const History = () => {
  const classes = useStyles();

  return (
    <Container style={{ padding: "32px 8px 64px 8px" }}>
      <Typography variant="h4" style={{ color: "#353b48", marginBottom: 32 }}>
        Our History
      </Typography>
      <Typography className={classes.history}>
        Lifehouse Church was established in East London through the
        transformation of an existing church called Bikers Church which was
        established in 1986 by a group of people that had the passion and desire
        to see people saved and transformed by the grace of God in our city.
        Throughout the years the church has seen many different seasons of
        change and in 2019 the church underwent a process of transformation in
        order to reestablish the call and vision of God for the Church, and to
        extend an open door of relevance to our community. In January 2020
        Lifehouse was born. We are a group of people with the desire to see each
        individual connect to God, and to be equipped for their individual call
        in their sphere of influence, and to see our city and our country
        transformed by His presence.
      </Typography>
    </Container>
  );
};

export default History;
