import { Container, Typography } from "@material-ui/core";
import React from "react";
import YoutubeVideo from "../../Shared/YoutubeVideo/YoutubeVideo";
import useStyles from "./Introduction.styles";

const Introduction = () => {
  const classes = useStyles();

  return (
    <Container style={{ marginTop: 80, marginBottom: 32 }}>
      <Typography
        align="center"
        variant="h4"
        style={{ color: "#353b48", marginBottom: 32 }}
      >
        The Vision of Lifehouse Church is,
      </Typography>
      <Typography
        variant="h4"
        align="center"
        style={{ color: "#353b48", marginBottom: 32, fontWeight: 500 }}
      >
        Discover, Grow, Go!
      </Typography>
      <Typography variant="h5" style={{ color: "#353b48", marginBottom: 16 }}>
        What does this mean?
      </Typography>
      <Typography className={classes.paragraph}>
        We want to help people to discover God, find their freedom. We want to
        help people grow in their identity and find their purpose and we want to
        equip people to go out there and make a difference.
      </Typography>
      <YoutubeVideo
        style={{ margin: "16px 0" }}
        videoUrl="https://www.youtube.com/embed/BwKQGw2b4tI"
      />
    </Container>
  );
};

export default Introduction;
