import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = (url: string) => makeStyles(({ shadows, spacing }: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: spacing(2),
      right: spacing(2),
      width: spacing(8.5),
      height: spacing(8.5),
      borderRadius: '50%',
      background: `url(${url}) 50% 50% no-repeat`, /* 50% 50% centers image in div */
      boxShadow: shadows[2],
      '&:hover': {
        boxShadow: shadows[4],
      }
    },
  })
);

export default useStyles;
