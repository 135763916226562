import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    history: {
      letterSpacing: 1,
    }
  })
});

export default useStyles;