import * as React from "react";
import Layout from "../layouts/Layout";

import { Introduction } from "../components/Vision";

const Vision = () => {
  return (
    <Layout title="Vision">
      <div style={{ marginTop: 80 }} />
      <Introduction />
    </Layout>
  );
};

export default Vision;
