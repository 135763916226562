import * as React from "react";
import { Header, Footer } from "../components/Shared/";
import SEO from "../components/Shared/SEO/SEO";
import Whatsapp from "../components/Shared/Whatsapp/Whatsapp";

import useStyles from "./Layout.styles";

interface Props {
  title?: string;
  description?: string;
  transparent?: boolean;
  children: React.ReactNode;
  whatsapp?: boolean;
}

const Layout = ({
  title,
  description,
  transparent,
  whatsapp = true,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <SEO title={title} description={description} />
      <Header transparent={transparent} />
      <div className={classes.root}>{children}</div>
      {whatsapp && <Whatsapp />}
      <Footer />
    </>
  );
};

export default Layout;
